import React from "react"

import Layout from "../components/global/layout"
import SEO from "../components/global/seo"

const Cookies = () => (
  <Layout>
    <SEO title="Cookies" />
    <section className="container-left container-right privacy">
      <h1>INFORMATION COLLECTED AND STORED</h1>
      <p>
        We will not collect any personal information about you except where it
        is specifically and knowingly provided by you, e.g. if you register for
        updates, or as a media centre user, or if you email us to provide
        feedback on the website.
      </p>

      <p>
        When personal information is collected, it may be used by us to
        corroborate your identity, to process and fulfil requests for
        information or help us to develop the website to make it more useful to
        you.
      </p>

      <p>
        Cookies are small text files sent to your computer. Although cookies
        contain identifying information, they do not contain any information
        that identifies you personally, but they allow us to recognise you when
        you return to our website. If you do not want to accept a cookie when
        you visit our website, you should be able to change the settings in your
        browser to disable this function, but there might be sections of the
        site that you are unable to access - in general, those sections for
        which you have specifically registered.
      </p>

      <p>
        If you send us an email (e.g. to request information or provide feedback
        on website content), the information you provide will only be used to
        help us gather the information requested and to respond to your message.
      </p>
    </section>
  </Layout>
)

export default Cookies
